<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Voucher No</th>
                    <th>GRN Number</th>
                    <th>PO Number</th>
                    <th>Supplier Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in list.data" :key="i">
                    <td>{{ item.date }}</td>
                    <td>{{ item.bill_number }}</td>
                    <td>{{ item.grn_master ? item.grn_master.grn_number : '' }}</td>
                    <td>{{ item.purchase_order_master.po_number }}</td>
                    <td>{{ item.contact_profile.supplier_name }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm" title="Edit LC"
                                @click="edit(item)"
                                style="cursor: pointer"
                        >
                            <i class="fas fa-edit"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <p v-if="!list.total" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
    list: Object,
});
const route = useRoute();
const router = useRouter();

function edit(item) {
    let param = {
        companyId: route.params.companyId,
        moduleId: route.params.moduleId,
        menuId: route.params.menuId,
        pageId: route.params.pageId,
    }

    let query = route.query;
    query.landedCosting = item.id;

    router.push({
        name: 'landed-costing-edit',
        params: param,
        query: query
    })
}
</script>
